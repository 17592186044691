import React from 'react'
import HeroSection from './HeroSection'
import ScrollAnimation from 'react-animate-on-scroll'

export default () => (
  <HeroSection
    swapOnMobile
    left={
      <div>
        <ScrollAnimation animateIn="fadeInUp" duration={0.5}>
          <img
            className="screenshot-left no-scale"
            src="/images/app/notifications/daily-recap@2x.png"
            alt="daily recap feature"
            style={{ marginBottom: '7%' }}
          />
        </ScrollAnimation>
        <img
          className="screenshot-left"
          src="/images/app/app-screen-3@2x.png"
          alt="daily milestones feature"
        />
      </div>
    }
    right={
      <>
        <p className="topic">physical well-being</p>
        <h2>Stay active</h2>
        <p className="description">
          Track the steps you naturally take each day and get personalized
          updates each morning encouraging you to go further.
        </p>
      </>
    }
  />
)
